<template>
  <section class="auth-wrapper">
    <alert v-if="alert" :alert-data="alert"/>
    <reset-password-form
      v-if="showFormNewPass"
      @onSubmit="submitResetPass"
      :loading="loading"
    />
    <forgot-password-form
      v-else
      @onSubmit="submitForgotPass"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

const Alert = () => import('@/shared/components/Alert')
const ForgotPasswordForm = () => import('../components/ForgotPasswordForm')
const ResetPasswordForm = () => import('../components/ResetPasswordForm')
export default {
  name: 'ResetPassword',
  components: {
    Alert,
    ForgotPasswordForm,
    ResetPasswordForm
  },
  data: () => ({
    alert: {},
    valid: false,
    showFormNewPass: false,
    loading: false
  }),
  mounted () {
    this.queryHasToken()
  },
  watch: {
    '$route.query' () {
      this.queryHasToken()
    }
  },
  methods: {
    ...mapActions('user', ['updateUserPassword', 'fetchUser']),
    ...mapActions('auth', ['resetPassword', 'logIn']),
    queryHasToken () {
      this.$route.query.token
        ? (this.showFormNewPass = true)
        : (this.showFormNewPass = false)
    },
    submitResetPass (formData) {
      this.alert = {}
      const data = {
        token: this.$route.query.token,
        password: formData.password
      }
      this.updateUserPassword(data)
        .then(async () => {
          this.setAlert({ status: 'success', msg: 'Пароль успішно змінено!' })
          if (this.$cookies.isKey('_email')) {
            await this.logIn({
              email: this.$cookies.get('_email'),
              password: formData.password
            })
              .then(() => {
                this.redirectTo('/')
              })
              .catch((e) =>
                this.setAlert({ status: 'error', msg: `Log in error ${e}` })
              )
          }
        })
        .catch((er) => {
          switch (er.response.status) {
            case 401:
              this.setAlert({ status: 'error', msg: er.response.data })
              break
            case 400:
              this.setAlert({ status: 'error', msg: 'Щось трапилось' })
              break
          }
          this.$router.push(this.$route.path)
        })
    },
    submitForgotPass (email) {
      this.alert = {}
      this.loading = true
      const obj = {
        email
      }
      if (this.$route.query.redirect) {
        obj.queryParams = [
          {
            key: 'redirect',
            value: this.$route.query.redirect
          }
        ]
      }
      this.resetPassword(obj)
        .then(() =>
          this.setAlert({
            status: 'success',
            msg: 'На вказану пошту відправлено листа!'
          })
        )
        .catch((err) => {
          this.setAlert({ status: 'error', msg: err })
        })
        .finally(() => (this.loading = false))
    },
    setAlert ({ status, msg, timeout = 5000 }) {
      this.alert = {
        status,
        msg
      }
      setTimeout(() => (this.alert = {}), timeout)
    },
    redirectTo (path) {
      const redirect = this.$route.query.redirect || path
      const query = Object.fromEntries(
        Object.entries(this.$route.query).filter((el) => el[0] !== 'redirect')
      )
      this.$router.push({ path: redirect, query }).catch(() => {
      })
    }
  }
}
</script>
